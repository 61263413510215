import emailjs from "@emailjs/browser";

import React, { useRef } from "react";
export default function ContactMe() {
  {
    const form = useRef();
    const sendEmail = (e) => {
      e.preventDefault();
      emailjs
        .sendForm(
          process.env.REACT_APP_SERVICE_ID,
          process.env.REACT_APP_TEMPLATE_ID,
          form.current,
          process.env.REACT_APP_PUBLIC_KEY
        )
        .then(
          (result) => {
            alert("Mensaje enviado correctamente!");
            console.log(result.text);
          },
          (error) => {
            console.log(error.text);
          }
        );
    };
    return (
      <section id="Contact" className="contact--section">
        <div>
          <h1 className="contact--title">Contáctame</h1>
        </div>
        <form
          className="contact--form--container"
          ref={form}
          onSubmit={sendEmail}
        >
          <div className="container">
            <label htmlFor="first-name" className="contact--label">
              <span className="contact--span">Nombre</span>
              <input
                type="text"
                className="contact--input text-md"
                name="from_name"
                id="first-name"
                required
              />
            </label>
            <label htmlFor="last-name" className="contact--label">
              <span className="contact--span">Apellidos</span>
              <input
                type="text"
                className="contact--input text-md"
                name="from_lastname"
                id="last-name"
                required
              />
            </label>
            <label htmlFor="email" className="contact--label">
              <span className="contact--span">Email</span>
              <input
                type="email"
                className="contact--input text-md"
                name="from_email"
                id="email"
                required
              />
            </label>
            <label htmlFor="phone-number" className="contact--label">
              <span className="contact--span">Número de teléfono</span>
              <input
                type="tel"
                className="contact--input text-md"
                name="from_phone"
                id="phone-number"
                required
                pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
              />
            </label>
          </div>
          <label htmlFor="message" className="contact--label">
            <span className="contact--span">Mensaje</span>
            <textarea
              className="contact--input text-md"
              name="message"
              id="message"
              rows="8"
              placeholder="Escribe tu mensaje..."
            />
          </label>
          <div>
            <button className="btn btn-primary contact--form--btn">
              Enviar
            </button>
          </div>
        </form>
      </section>
    );
  }
}
