import data from "../../data/index.json";

export default function MyPortfolio() {
  return (
    <section className="portfolio--section" id="MyPortfolio">
      <div className="portfolio--container-box">
        <div className="portfolio--container">
          <h1 className="portfolio--section--heading">Portfolio</h1>
          <p className="hero--section-description">
            Te invito a visitar mi Github para que le eches un vistazo a mis
            repositorios y proyectos personales.
          </p>
        </div>
        <div>
          <a
            href="https://github.com/ayozejesus"
            target="_blank"
            className="btn btn-github"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              viewBox="0 0 33 33"
              fill="#ffa500"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 0C5.37 0 0 5.37 0 12c0 5.302 3.438 9.798 8.206 11.385.6.11.793-.261.793-.577 0-.285-.01-1.042-.015-2.044-3.338.724-4.042-1.613-4.042-1.613-.546-1.385-1.333-1.753-1.333-1.753-1.088-.743.083-.728.083-.728 1.205.085 1.838 1.238 1.838 1.238 1.07 1.835 2.807 1.305 3.495.998.108-.776.42-1.305.763-1.605-2.665-.303-5.466-1.332-5.466-5.93 0-1.31.465-2.382 1.235-3.223-.124-.303-.535-1.524.116-3.176 0 0 1.007-.322 3.3 1.23.96-.266 1.98-.398 3-.405 1.02.007 2.04.139 3 .405 2.29-1.552 3.295-1.23 3.295-1.23.655 1.652.244 2.873.12 3.176.77.84 1.233 1.913 1.233 3.223 0 4.61-2.805 5.624-5.475 5.92.43.37.81 1.102.81 2.224 0 1.606-.015 2.896-.015 3.287 0 .32.192.695.8.576C20.566 21.794 24 17.298 24 12c0-6.63-5.37-12-12-12"
                fill="currentColor"
              />
            </svg>
            Visita mi Github
          </a>
        </div>
      </div>
      <div className="portfolio--section--container">
        {data?.portfolio?.map((item, index) => (
          <div key={index} className="portfolio--section--card">
            <div className="portfolio--section--img">
              <img src={item.src} alt="Placeholder" />
            </div>
            <div className="portfolio--section--card--content">
              <div>
                <h3 className="portfolio--section--title">{item.title}</h3>
                <p className="text-md">{item.description}</p>
              </div>
              <a
                href={item.link}
                target="_blank"
                rel="noopener noreferrer"
                className="text-sm portfolio--link"
              >
                {item.linkText}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 20 19"
                  fill="none"
                >
                  <path
                    d="M4.66667 1.66675H18V15.0001M18 1.66675L2 17.6667L18 1.66675Z"
                    stroke="currentColor"
                    strokeWidth="2.66667"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </a>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}
