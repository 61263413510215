import { useState, useEffect } from "react";
import data from "../../data/index.json";

export default function MySkills() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 1024);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 1024);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? data.skills.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === data.skills.length - 1 ? 0 : prevIndex + 1
    );
  };

  return (
    <section className="skills--section" id="mySkills">
      <div className="portfolio--container">
        <h1 className="skills--section--heading">Habilidades</h1>
      </div>
      <div className="skills--section--container">
        {data.skills.map((item, index) => (
          <div
            key={index}
            className={`skills--section--card ${
              index === currentIndex ? "active" : ""
            }`}
            style={{
              display: isSmallScreen
                ? index === currentIndex
                  ? "block"
                  : "none"
                : "flex",
            }}
          >
            <div className="skills--section--img">
              <img src={item.src} alt={item.title} />
            </div>
            <div className="skills--section--card--content">
              <h3 className="skills--section--title">{item.title}</h3>
              <p className="skills--section--description">{item.description}</p>
            </div>
          </div>
        ))}
      </div>
      {isSmallScreen && data.skills.length > 1 && (
        <div className="arrow-buttons">
          <button onClick={handlePrev}>&#8592;</button>
          <button onClick={handleNext}>&#8594;</button>
        </div>
      )}
    </section>
  );
}
