export default function AboutMe() {
  return (
    <section id="AboutMe" className="about--section">
      <div className="about--section--img">
        <img src="./img/about-me.png" alt="About Me" />
      </div>
      <div className="hero--section--content--box about--section--box">
        <div className="hero--section--content">
          <h1 className="skills-section--heading">Sobre mí</h1>
          <p className="hero--section-description">
            Soy un desarrollador Full Stack con un fuerte compromiso por crecer
            y evolucionar en el mundo de la programación. Disfruto aplicando
            buenas prácticas en mis proyectos, siguiendo los patrones de Clean
            Code y manteniéndolos escalables y eficientes. Estoy deseando
            enfrentar nuevos retos y trabajar en un equipo colaborativo y
            dinámico. Mi pasión por el desarrollo de software se refleja en mi
            constante búsqueda de aprender nuevas tecnologías y mantenerme al
            tanto de las tendencias del sector.
          </p>
        </div>
      </div>
    </section>
  );
}
